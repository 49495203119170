<template>
  <div class="video-album">
    <div
      class="video-album-head"
      :style="{ background: backgroundImg}"
    >
    </div>
    <div class="video-album-content">
      <p class="title ellipsis-2">{{albumDetail.name}}</p>
      <p class="content ellipsis-1">{{albumDetail.lecturer}}</p>
    </div>
    <div class="audio-album-content">
      <p class="list-num">共{{albumList.length}}个音频</p>
      <div
        class="audio-album-item"
        v-for="item in albumList"
        :key="item.id"
        @click="toVideo(item)"
      >
        <div class="left">
          <img
            :src="item.pic"
            onerror="this.src='imgs/位图.png'"
            alt=""
          >
          <i class="icon el-icon-caret-right"></i>
          <p class="time-show">{{item.time}}</p>
        </div>
        <div class="right">
          <p class="title ellipsis-2">
            {{item.title}}
          </p>
          <div class="time-times">
            <i class="el-icon-video-play"></i>
            <span>{{item.play_count}} · {{item.create_time.substring(0, 10)}}</span>
          </div>
        </div>
      </div>
    </div>
    <ToApp :appLink="{
      type: 'videoAlbum',
      id: $route.query.id
    }"></ToApp>
    <ShareBtn></ShareBtn>
  </div>
</template>

<script>
import ToApp from '@/components/ToApp.vue';
import ShareBtn from '@/components/ShareBtn.vue';
import { get } from '../http.js'
export default {
  components: {
    ToApp,
    ShareBtn
  },
  data() {
    return {
      backgroundImg: '',
      albumDetail: {
        pic: 'imgs/位图.png'
      },
      albumList: [],
      isError: false
    }
  },
  mounted() {
    // 52
    let { id } = this.$route.query;
    get({ url: `album/${id}/` }).then((response) => {
      let data = response;
      this.backgroundImg = `url(${data.cover + '?imageView2/1/w/200/h/200/q/60'})`
      this.albumDetail = {
        pic: data.cover,
        name: data.name,
        lecturer: data.lecturer_display,
      }
      this.albumList = data.items;
    }).catch(error => {
      this.isError = true;
      let data = error;
      this.backgroundImg = `url(${data.cover + '?imageView2/1/w/200/h/200/q/60'})`
      this.albumDetail = {
        pic: data.cover,
        name: data.name,
        lecturer: data.lecturer_display,
      }
      this.albumList = data.items;
    })
  },
  methods: {
    toVideo(item) {
      if (this.isError) {
        this.$store.commit('changePermission')
      } else {
        this.$router.push('video?id=' + item.id)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.video-album {
  width: 100%;
  min-height: 100vh;
  background: #f0f0f0;
  .video-album-head {
    width: 100%;
    height: 4rem;
    background: url(/imgs/位图.png);
    background-repeat: no-repeat !important;
    background-size: cover !important;
    filter: blur(1px) opacity(0.9);
    position: relative;
    &:before {
      content: " ";
      position: absolute;
      width: 100%;
      height: 4rem;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  .video-album-content {
    position: absolute;
    top: 0.8rem;
    left: 0.3rem;
    width: 5.8rem;
    .title {
      font-size: 0.48rem;
      font-weight: normal;
      color: rgba(255, 255, 255, 1);
      line-height: 0.7rem;
    }
    .content {
      font-size: 0.36rem;
      font-weight: normal;
      color: rgb(255, 255, 255);
      line-height: 0.59rem;
    }
  }
}
.audio-album-content {
  padding: 0.3rem;
  padding-bottom: 1.5rem;
  .list-num {
    font-size: 0.26rem;
    font-weight: 300;
    color: rgb(153, 153, 153);
    line-height: 0.37rem;
  }
  .audio-album-item {
    display: flex;
    padding: 0.2rem 0;
    position: relative;
    // border-bottom: 1px solid #cccccc;
    .left {
      width: 3rem;
      font-size: 0.23rem;
      color: #ffffff;
      padding: 0.1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      img {
        width: 2.8rem;
        height: 1.58rem;
        object-fit: cover;
        background: #000000;
      }
      .icon {
        position: absolute;
        top: 0.75rem;
        left: 1.35rem;
        color: #ffffff;
        font-size: 0.5rem;
      }
      .time-show {
        position: absolute;
        right: 0.25rem;
        bottom: 0.25rem;
        color: #ffffff;
        padding: 0.05rem 0.1rem;
        background: rgba(0, 0, 0, 0.6);
      }
    }
    .right {
      width: 3rem;
      .title {
        font-size: 0.48rem;
        font-weight: normal;
        color: rgb(51, 51, 51);
        line-height: 0.7rem;
        height: 1.4rem;
      }
      .time-times {
        margin-top: 0.2rem;
        font-size: 0.24rem;
        font-weight: 300;
        color: rgb(102, 102, 102);
        line-height: 0.33rem;
        font-family: PingFangSC-Light, PingFang SC;
        span {
          padding: 0 0.1rem;
        }
      }
    }
  }
}
.share-icon {
  position: absolute;
  top: 0.3rem;
  right: 0.5rem;
  color: #ffffff;
  z-index: 1;
}
</style>