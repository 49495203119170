<template>
  <div>
    <div
      class="videoBox1"
      ref="videoBox1"
    >
      <!-- <video id="my_video_1" ref="videoPlayer" width="100%" autoplay="true" :src="videoUrl" preload="metadata" controls webkit-playsinline="true" playsinline="true" x-webkit-airplay="allow" x5-video-player-type="h5" x5-video-player-fullscreen="false" x5-video-orientation="landscape"> -->
      <!-- 抱歉，您的浏览器不支持内嵌视频!
      </video> -->
      <video-player
        id="my_video_1"
        class="vjs-custom-skin"
        ref="videoPlayer"
        :options="playerOptions"
        @ready="playerReadied"
        @pause="onPlayerPause($event)"
        @play="play"
        @canplaythrough="onPlayerCanplaythrough($event)"
        @waiting="onPlayerWaiting($event)"
        @playing="onPlayerPlaying($event)"
        @loadeddata="onPlayerLoadeddata($event)"
        @timeupdate="onPlayerTimeupdate($event)"
        @canplay="onPlayerCanplay($event)"
      >
      </video-player>
    </div>
  </div>
</template>

<script>
require("videojs-flash");
export default {
  props: {
    videoUrl: "",
    poster: ""
  },
  data() {
    return {
      player: {},
      playerOptions: {
        // height: 0,
        // videojs options
        autoplay: false, // 自动播放
        // live: true,
        controls: true, // 是否显示控制栏
        sourceOrder: true,
        flash: { hls: { withCredentials: false } },
        html5: { hls: { withCredentials: false } },
        techOrder: ["html5", "flash"], // 兼容顺序
        language: "en",
        poster: "",
        // playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            // 流配置，数组形式，会根据兼容顺序自动切换
            type: "video/mp4",
            src: ""
          },
          {
            withCredentials: false,
            type: "application/x-mpegURL",
            src: ""
          }
        ]
      },
      scheduleLock: true,
      lesson_list: []
    };
  },
  methods: {
    // 获取播放信息
    getVideo() { },
    // 后去播放地址
    getVideoUrl(id) { },
    // 选择播放
    chioseVideoUrl(id) { },
    onPlayerPause(player) {
      console.log(player);
    },
    // 播放
    play() {
      // if (!this.playerStatus.ready) {
      //   return
      // }
      // console.log(this.$refs.videoPlayer);
      // console.log("this.$refs.videoPlayer");
      // setTimeout(() => {
      //   console.log("执行力");
      //   $(".vjs-fullscreen-control").click();
      // }, 10);
      this.$emit("hidePlayBtn");
    },
    // 加载完成，可以播放
    onPlayerCanplaythrough(event) {
      // 设置音量
      // this.player.volume(1)
    },
    onPlayerWaiting() {
      console.log("选择时间");
    },
    onPlayerPlaying(event) {
      // console.log('正在播放')
    },
    onPlayerLoadeddata() {
      console.log("onPlayerLoadeddata");
    },
    onPlayerTimeupdate() { },
    onPlayerCanplay() {
      console.log("onPlayerCanplay");
    },
    playerReadied() {
      const video = document
        .getElementById("my_video_1")
        .getElementsByTagName("video")[0];
      // video.setAttribute("x5-video-player-type", "h5");
      video.setAttribute("x5-playsinline", true);
      video.setAttribute("playsinline", true);
      video.setAttribute("webkit-playsinline", true);
    },
    // 更新进度
    upDataSchedule(status) { }
  },
  created() { },
  // watch: {
  //   played: function(newP, oldP) {
  //     console.log(oldP);
  //     this.$refs.videoPlayer.player.pause();
  //   }
  mounted() {
    this.playerOptions.height = this.$refs.videoBox1.clientHeight;
    // this.videoUrl = this.$route.query.url;
    this.playerOptions.sources[0].src = this.videoUrl;
    this.playerOptions.poster =
      this.videoUrl + "?vframe/jpg/offset/3/w/480/h/281";
    if (this.poster) {
      this.playerOptions.poster = this.poster;
    }
    console.log(this.playerOptions)
    // this.$refs.videoPlayer.player.reset();
  }
};
</script>

<style lang="less" scoped>
.videoBox1 {
  width: 100%;
  height: 4.22rem;
  div {
    height: 100%;
  }
  /deep/.vjs-big-play-button {
    border-radius: 50% !important;
    width: 2em !important;
    margin-left: -1em !important;
    z-index: 99;
    .vjs-icon-play {
      &:before {
        padding: 0.05rem !important;
      }
    }
  }
  /deep/.vjs-progress-control {
    .vjs-load-progress {
      background: #ffffff !important;
    }
    .vjs-play-progress {
      background: #e8380d;
      &::before {
        color: #e8380d;
      }
    }
    div {
      background: #ffffff;
    }
  }
  /deep/.vjs-loading-spinner {
    height: 50px !important;
  }
  /deep/.vjs-custom-skin {
    z-index: 3;
  }
  /deep/.video-js {
    width: 100% !important;
  }
  /deep/.vjs-control-bar {
    background-color: rgba(43, 51, 63, 0.3);
  }
}
</style>