<template>
  <div class="ryk-audio">
    <div
      class="audio-heade"
      :style="{background: backgroundImg}"
    >
    </div>
    <div class="dang-gan">
      <img
        src="imgs/danggan.png"
        alt=""
      >
    </div>
    <audio
      style="display: none;"
      ref="audio"
      @pause="onPause"
      @play="onPlay"
      @timeupdate="onTimeupdate"
      @loadstart="onLoadstart"
      @loadedmetadata="onLoadedmetadata"
      :src="audioDetail.url"
      controls="controls"
    >
    </audio>
    <div class="ryk-song-wrap">
      <div class="ryk-wrap">
        <div class="ryk-song-disc">
          <!-- :class="{'animation-paused': !playing}" -->
          <div class="ryk-song-rollwrap">
            <div class="song-img">
              <img
                :src="audioDetail.pic"
                onerror="this.src='imgs/位图.png'"
                alt=""
              >
            </div>
          </div>
        </div>
      </div>
      <img
        src="imgs/播放.png"
        v-if="!playing"
        class="play"
        @click="play"
        alt=""
      >
      <img
        src="imgs/暂停1.png"
        v-if="playing"
        class="play"
        @click="pause"
        alt=""
      >
      <!-- <div
        v-if="playing"
        class="play"
        style="opacity: 0"
        @click="pause"
      ></div> -->
    </div>
    <div class="ryk-progress-bar">
      <div class="progress-time">
        <p>{{videoCurrentTime}}</p>
      </div>
      <el-slider
        style="width: 5rem;padding: 0px 0.3rem;margin-bottom: 0.25rem;padding-left: 0.5rem;"
        :show-tooltip="false"
        v-model="audio.sliderTime"
        :max="audio.duration"
        @change="changeCurrentTime"
      ></el-slider>
      <div class="progress-time">
        <p>{{videoDuration}}</p>
      </div>
    </div>
    <div class="head-title">
      <p class="name">{{audioDetail.title}}</p>
      <p class="lecturer">{{audioDetail.lecturer}}</p>
      <p class="times">播放：{{audioDetail.play_count}} 次. {{audioDetail.create_time}}</p>
    </div>
    <div
      class="audio-content"
      style="overflow-y: auto;"
      v-html="audioDetail.desc"
    >
    </div>
    <ToApp :appLink="{
      type: 'voice',
      id: $route.query.id
    }"></ToApp>
    <ShareBtn></ShareBtn>
  </div>
</template>
<script>
import ToApp from '@/components/ToApp.vue';
import ShareBtn from '@/components/ShareBtn.vue';
import { changeTimeBySecond } from '../utils.js';
import $ from 'jquery';
import { get } from '../http.js'
export default {
  components: {
    ToApp,
    ShareBtn
  },
  computed: {
    player() {
      return this.$refs.audio;
    },
    videoDuration() {
      return changeTimeBySecond(this.audio.duration);
    },
    videoCurrentTime() {
      return changeTimeBySecond(this.audio.currentTime);
    }
  },
  data() {
    return {
      audioDetail: {
        // pic: 'imgs/位图.png'
      },
      audio: {
        duration: 0,
        currentTime: 0,
        sliderTime: 0
      },
      isError: true,
      backgroundImg: "",
      playing: false
    }
  },
  mounted() {
    // 73
    let { id } = this.$route.query;
    let that = this;
    get({ url: `voice/${id}/` }).then((response) => {
      let data = response;
	  console.info(data);
	  console.info(this.audioDetail);
      this.audioDetail = {
        create_time: data.create_time,
        desc: data.desc ? data.desc.replace(/\n/g, '<br>') : '',
        is_collection: data.is_collection,
        pic: data.pic,
        play_count: data.play_count,
        time: data.time,
        title: data.title,
        url: data.url,
        id: data.id,
        lecturer_name: data.lecturer_name
      }
	  console.info(this.audioDetail);
      if (data.pic) this.backgroundImg = `url(${data.pic + '?imageView2/1/w/200/h/200/q/50'})`
    }).catch(error => {
      that.isError = true;
      let data = error;
      this.audioDetail = {
        create_time: data.create_time,
        desc: data.desc,
        is_collection: data.is_collection,
        pic: data.pic,
        play_count: data.play_count,
        time: data.time,
        title: data.title,
        url: data.url,
        id: data.id,
        lecturer: data.album.lecturer_display
      }
      if (data.pic) this.backgroundImg = `url(${data.pic + '?imageView2/1/w/200/h/200/q/50'})`
      setTimeout(() => {
        this.$store.commit('changePermission')
      }, 1000);
    })
  },
  methods: {
    play() {
      console.log(this.isError)
      this.$refs.audio.play();
    },
    pause() {
      this.$refs.audio.pause();
    },
    onPause() {
      console.log('这是暂停');
      this.playing = false;
      let siteImg = $('.ryk-song-disc ').css('transform')  //获取当前元素的动画改变，transform的值
      let siteWp = $('.ryk-wrap').css('transform')
      $('.ryk-wrap').css('transform', siteWp === 'none' ? siteImg : siteImg.concat('', siteWp))
      //由于父元素没有动画，所以每次赋值的时候，需要将上次父元素的状态加上
      $('.ryk-song-disc ').removeClass('ryk-song-playing')
    },
    onPlay() {
      console.log('这是开始');
      // if (!this.isError) {
        $('.ryk-song-disc ').addClass('ryk-song-playing');
        this.playing = true;
      // }
    },
    // 当timeupdate事件大概每秒一次，用来更新音频流的当前播放时间
    onTimeupdate(res) {
      this.audio.currentTime = res.target.currentTime;
      this.audio.sliderTime = res.target.currentTime;
      // this.audio.sliderTime = parseInt(this.audio.currentTime / this.audio.duration * 100);
    },
    onLoadstart() {
      console.log('金士顿')
      this.isError = false
    },
    // 语音元数据主要是语音的长度之类的数据
    onLoadedmetadata(res) {
      console.log('加载res')
      if (!res) {
        this.$toast('音频加载错误');
      }
      this.audio.duration = parseInt(res.target.duration);
      console.log('duration', this.audio.duration);
      this.volume = parseInt(res.target.volume * 100);
    },
    changeCurrentTime(num) {
      // this.$refs.audio.currentTime = parseInt(num / 100 * this.audio.duration)
      this.$refs.audio.currentTime = num;
    },
  },
}
</script>
<style lang="less" scoped>
.ryk-audio {
  width: 100%;
  // min-height: 100vh;
  // background: #f0f0f0;
}
.audio-heade {
  width: 100%;
  height: 7.5rem;
  overflow: hidden;
  background: url(/imgs/位图.png);
  background-repeat: no-repeat !important;
  background-size: cover !important;
  position: absolute;
  top: 0;
  z-index: -1;
  filter: blur(5px) opacity(0.9);
  &:before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 7.5rem;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
.animation-paused {
  animation-play-state: paused !important;
}
.ryk-song-wrap {
  z-index: 1;
  position: relative;
  .ryk-song-disc {
    width: 5rem;
    height: 5rem;
    margin: 0 auto;
    position: relative;
    margin-top: 1.2rem;
    &:before {
      content: " ";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
      background: url("/imgs/唱碟背景.png") no-repeat;
      background-size: contain;
    }
  }
  .ryk-song-playing {
    animation: myRotate 20s linear infinite;
  }
}
.ryk-song-rollwrap {
  position: absolute;
  width: 3rem;
  height: 3rem;
  left: 50%;
  top: 50%;
  z-index: 3;
  margin: -1.5rem 0 0 -1.5rem;
  .song-img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      z-index: 3;
      background: #ffffff;
      object-fit: cover;
    }
  }
}
.play {
  position: absolute;
  width: 1rem;
  height: 1rem;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background: rgba(0, 0, 0, 0.6);
  padding: 0.3rem;
  border-radius: 50%;
  margin: auto;
  z-index: 3;
  cursor: pointer;
}
.dang-gan {
  img {
    width: 1.3rem;
    height: 1.8rem;
  }
  position: absolute;
  top: 0.3rem;
  left: 3.5rem;
  z-index: 9;
}
.head-title {
  padding: 0.3rem 0.24rem;
  background: #ffffff;
  .name {
    font-size: 0.48rem;
    font-weight: normal;
    color: rgb(51, 51, 51);
    line-height: 0.7rem;
    width: 85%;
  }
  .lecturer {
    font-size: 0.36rem;
    font-weight: normal;
    color: rgb(102, 102, 102);
    line-height: 0.59rem;
  }
  .times {
    margin-top: 0.1rem;
    font-size: 0.24rem;
    font-weight: 300;
    color: rgba(102, 102, 102);
    line-height: 0.33rem;
  }
}
@keyframes myRotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
.audio-content {
  padding: 0.3rem;
  padding-bottom: 1.8rem;
}
.ryk-progress-bar {
  height: 1.3rem;
  padding: 0 0.3rem;
  display: flex;
  align-items: center;
  font-size: 0.2rem;
  color: #ffffff;
  .progress-time {
    width: 0.4rem;
    text-align: center;
  }
  /deep/.el-slider__runway {
    height: 3px;
    margin: 0;
    margin-top: 16px;
    margin-bottom: 0px;
  }
  /deep/.el-slider__bar {
    height: 3px;
    background-color: #ffffff;
  }
  /deep/.el-slider__button {
    width: 12px;
    height: 12px;
    border: none;
    background-color: #ffffff;
  }
  /deep/.el-slider__button-wrapper {
    height: 34px;
    width: 34px;
  }
  /deep/.el-slider__runway {
    background-color: #b3b3b3;
  }
}
.share-icon {
  position: absolute;
  top: 0.3rem;
  right: 0.5rem;
  color: #ffffff;
  z-index: 1;
}
</style>