<template>
  <div class="ryk-video">
    <div
      class="head-video"
      style="position: relative;"
      v-if="timeToplay"
    >
      <!-- <VideoLivePlayer :videoUrl="courseData"></VideoLivePlayer> -->
      <div
      class="videoBox1"
      ref="videoBox1"
    >
      <!-- <video id="my_video_1" ref="videoPlayer" width="100%" autoplay="true" :src="videoUrl" preload="metadata" controls webkit-playsinline="true" playsinline="true" x-webkit-airplay="allow" x5-video-player-type="h5" x5-video-player-fullscreen="false" x5-video-orientation="landscape"> -->
      <!-- 抱歉，您的浏览器不支持内嵌视频!
      </video> -->
      <!-- <video-player
        id="my_video_1"
        class="vjs-custom-skin"
        ref="videoPlayer"
        :options="playerOptions"
        @ready="playerReadied"
        @pause="onPlayerPause($event)"
        @play="play"
        @canplaythrough="onPlayerCanplaythrough($event)"
        @waiting="onPlayerWaiting($event)"
        @playing="onPlayerPlaying($event)"
        @loadeddata="onPlayerLoadeddata($event)"
        @timeupdate="onPlayerTimeupdate($event)"
        @canplay="onPlayerCanplay($event)"
      >
      </video-player> -->
      <!-- <xgplayer 
        id="my_video_1"
        class="vjs-custom-skin"
        ref="videoPlayer"  
        :options="playerOptions"></xgplayer >-->
      <div id="mse"></div>
      <!-- <div v-if="isAndroidshow && isAndroidshowshow == 1"  id="mse1"></div>
      <div v-if="isAndroidshow && isAndroidshowshow == 2"  id="mse2"></div> -->
      <!-- <div class="prism-player" id="player-con"></div> -->
    </div> 
    </div>
    <div
      class="wating-show"
      v-else
    >
      <div
        v-show="timeTitle"
        class="wating-show-content"
      >
        <p>{{timeTitle}}</p>
      </div>
    </div>
    <div class="head-title">
      <!-- <ShareBtn :type="'gray'"></ShareBtn> -->
      <p class="name">{{courseData.title}}</p>

      <!-- <div class="status">
        <span>{{courseData.status_display}}</span>
      </div> -->
      <p class="lecturer">{{courseData.lecturer_display}}</p>
      <div class="sstatus" v-if="isAndroidshow">
        <span v-if="isAndroidshowshow == 1" @click="changeyyy(1)"><img src="imgs/11111.png" alt="">仅音频播放</span>
        <span v-if="isAndroidshowshow == 2" @click="changeyyy(2)"><img src="imgs/22222.png" alt="">打开画面</span>
      </div>
      <p class="times">开始时间：{{courseData.start_time}}</p>
    </div>
    <div
      class="video-content"
      style="overflow-y: auto;"
      v-html="courseData.detail"
    >
    </div>
    <ToApp :appLink="{
      type: 'live'
    }"></ToApp>
  </div>
</template>

<script>
import VideoLivePlayer from '@/components/VideoLivePlayer.vue';
import ToApp from '@/components/ToApp.vue';
import ShareBtn from '@/components/ShareBtn.vue';
import { formatSeconds } from '../utils.js';
import { get } from '../http.js'
export default {
  components: {
    VideoLivePlayer,
    ToApp,
    ShareBtn
  },
  data() {
    return {
      courseData: {},
      timeToplay: false,
      isAndroidshow:false,
      isAndroidshowshow:1,
      timeTitle: ''
    }
  },
  mounted() {
    this.getDetail();
    this.isAndro();
    get({ url: `course/` }).then((res) => {
         let url = res.live_infos[0].url;
            let poster = url + "?vframe/jpg/offset/3/w/480/h/281";
            let line = [{"name":res.live_infos[0].name,"url":res.live_infos[0].url},{"name":res.live_infos[2].name,"url":res.live_infos[2].url}]
            console.log(line);
            let player = new HlsJsPlayer({
              id: 'mse',
              url: url,
              isLive: true,
              autoplay: true,
              playsinline: true,
              id: "mse",
              url: url,
              playsinline: true,
              whitelist: [""],
              autoplay: true,
              fluid: true,
              resourceReady:"线路一",
              poster: player
            });
            player.emit('resourceReady', line)
      })
  },
  destroyed() {
    clearInterval(this.interval);
    this.interval = null;
  },
  methods: {
    changeyyy(e){
      // console.log(e);
       var oUl = document.getElementById('mse');
       var childs = oUl.childNodes; 
      for(var i = childs.length - 1; i >= 0; i--) { 
        // alert(childs[i].nodeName); 
        oUl.removeChild(childs[i]); 
      }
      //  oUl.removeChild(oUl.children)
       console.log(childs);
      let y = e;
      if(y == 1){
          get({ url: `course/` }).then((res) => {
         let url = res.live_infos[2].url;
            let poster = url + "?vframe/jpg/offset/3/w/480/h/281";
            let line = [{"name":res.live_infos[0].name,"url":res.live_infos[0].url},{"name":res.live_infos[2].name,"url":res.live_infos[2].url}]

            let player = new HlsJsPlayer({
              id: "mse",
              url: url,
              playsinline: true,
              whitelist: [""],
              autoplay: true,
              fluid: true,
              resourceReady:"线路一",
            });
            player1.emit('resourceReady', line)
          })
          this.isAndroidshowshow = 2 
        }
      if(y == 2){
        console.log('this.isAndroidshowshow = 1 ');
        get({ url: `course/` }).then((res) => {
         let url = res.live_infos[0].url;
         console.log(url);
         let line = [{"name":res.live_infos[0].name,"url":res.live_infos[0].url},{"name":res.live_infos[2].name,"url":res.live_infos[2].url}]
            let poster = url + "?vframe/jpg/offset/3/w/480/h/281";
            let player = new HlsJsPlayer({
              id: "mse",
              url: url,
              playsinline: true,
              whitelist: [""],
              autoplay: true,
              fluid: true,
              resourceReady:"线路一",
            });
            player2.emit('resourceReady', line)
          })
        this.isAndroidshowshow = 1 

        }
    },
    isAndro(){
        var u = navigator.userAgent;
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
        if(isAndroid){
        console.log('这是安卓设备');
        this.isAndroidshow = true
        }
    },
    getDetail() {
      // 44
      get({ url: `course/` }).then((res) => {
        this.courseData = res
        if (res.live_infos) {
          this.canPlay = true;
          let showTimenew = res.start_time; // res.data.start_time
          let showTimenew2 = new Date(); // res.data.start_time

          let showTime = Date.parse(res.start_time.replace(/\-/g, "/")); // res.data.start_time
          let nowTime = Date.parse(new Date());
          
          if (nowTime > showTime) {
            this.timeToplay = true;
            console.log('是时候了');
          } else {
            this.timeToplay = false;
            console.log('还没到点');
            this.countdown();
          }
        }
      })
    },
    // 倒计时显示
    countdown() {
      let showTime = Date.parse(this.courseData.start_time);
      this.interval = setInterval(() => {
        let countdownTime = showTime - Date.parse(new Date());
        if (countdownTime < 1) {
          clearInterval(this.interval);
          this.timeToplay = true;
          console.log(countdownTime)
          console.log(showTime)
        }
        this.timeTitle = '距离开播还有：' + formatSeconds(countdownTime / 1000);
      }, 1000);
    }
  },
}
</script>
<style lang="less" scoped>
.ryk-video {
  width: 100%;
  min-height: 100vh;
  background: #f0f0f0;
}
.head-title {
  padding: 0.3rem 0.24rem;
  background: #ffffff;
  position: relative;
  .name {
    font-size: 0.48rem;
    font-weight: normal;
    color: rgb(51, 51, 51);
    line-height: 0.7rem;
    width: 80%;
    word-break: break-all;
  }
  .lecturer {
    font-size: 0.36rem;
    font-weight: normal;
    color: rgb(102, 102, 102);
    line-height: 0.59rem;
    display: inline-block;
  }
  .times {
    margin-top: 0.1rem;
    font-size: 0.24rem;
    font-weight: 300;
    color: rgb(102, 102, 102);
    line-height: 0.33rem;
  }
}
.wating-show {
  width: 100%;
  height: 4.22rem;
  background-image: url("http://audio-cdn.ruyiku.cn/static/files/佛法banner图片.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  .wating-show-content {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.15);
    p {
      padding: 0.2rem;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 0.1rem;
      font-size: 0.2rem;
      font-family: PingFangSC-Medium, PingFang SC;
      color: #ffffff;
      line-height: 0.24rem;
    }
  }
}
.video-content {
  padding: 0.3rem;
  padding-bottom: 1.8rem;
      white-space: initial;
    word-break: break-all;
}
.share-icon {
  position: absolute;
  right: 0.5rem;
  color: #919191;
}
.status {
  padding: 5px 0;
  span {
    color: #d81e06;
    border: 1px solid #d81e06;
    font-size: 13px;
    font-family: "PingFang SC", "Helvetica Neue", "Helvetica", "Arial",
      sans-serif;
    font-weight: 400;
    line-height: 18px;
    padding: 2px 10px;
    border-radius: 2px;
  }
}
#player-con{
  width: 100%;
  // height: auto;
}
.videoBox1 {
  width: 100%;
  height: 4.22rem;
  position: relative;
  // border: 1px solid red;
  div {
    height: 100%;
  }
  /deep/.vjs-big-play-button {
    border-radius: 50% !important;
    width: 2em !important;
    margin-left: -1em !important;
    z-index: 99;
    .vjs-icon-play {
      &:before {
        padding: 0.05rem !important;
      }
    }
  }
  /deep/.vjs-progress-control {
    .vjs-load-progress {
      background: #ffffff !important;
    }
    .vjs-play-progress {
      background: #e8380d;
      &::before {
        color: #e8380d;
      }
    }
    div {
      background: #ffffff;
    }
  }
  /deep/.vjs-loading-spinner {
    height: 50px !important;
  }
  /deep/.vjs-custom-skin {
    z-index: 3;
  }
  /deep/.video-js {
    width: 100% !important;
  }
  /deep/.vjs-control-bar {
    background-color: rgba(43, 51, 63, 0.3);
  }
}
.aniiu{
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99999;
  width: auto;
  height: 1rem;
  color: #fff;
}
.xgplayer {
    background:url(/imgs/aaa.jpg) top!important;
    background-size: 100% auto;
}
.sstatus{
    display:inline-flex;
    color: #d81e06;
    border: 1px solid #d81e06;
    font-size: 13px;
    font-family: "PingFang SC", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 18px;
    padding: 2px 10px;
    border-radius: 2px;
    justify-items: center;
    align-items: center;
    align-content: center;
    float: right;
}
.sstatus img{
  display: inline-block;
  width: .3rem;
  height: auto;
  margin: 2px 3px 0 0;
}
</style>
