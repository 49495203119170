<template>
  <div>
    <div
      class="videoBox1"
      ref="videoBox1"
    >
      <!-- <video id="my_video_1" ref="videoPlayer" width="100%" autoplay="true" :src="videoUrl" preload="metadata" controls webkit-playsinline="true" playsinline="true" x-webkit-airplay="allow" x5-video-player-type="h5" x5-video-player-fullscreen="false" x5-video-orientation="landscape"> -->
      <!-- 抱歉，您的浏览器不支持内嵌视频!
      </video> -->
      <!-- <video-player
        id="my_video_1"
        class="vjs-custom-skin"
        ref="videoPlayer"
        :options="playerOptions"
        @ready="playerReadied"
        @pause="onPlayerPause($event)"
        @play="play"
        @canplaythrough="onPlayerCanplaythrough($event)"
        @waiting="onPlayerWaiting($event)"
        @playing="onPlayerPlaying($event)"
        @loadeddata="onPlayerLoadeddata($event)"
        @timeupdate="onPlayerTimeupdate($event)"
        @canplay="onPlayerCanplay($event)"
      >
      </video-player> -->
      <!-- <xgplayer 
        id="my_video_1"
        class="vjs-custom-skin"
        ref="videoPlayer"  
        :options="playerOptions"></xgplayer >-->
      <div id="mse"></div>
      <!-- <div class="prism-player" id="player-con"></div> -->
    </div> 
  </div>
</template>

<script>

export default {
    props: {
    videoUrl: "",
    poster: "https://media.ruyiku.cn/imgs/logo.png"
  },
  mounted() {
      console.log(this.videoUrl)
      let url = this.videoUrl.live_infos[0].url;
      let poster = url + "?vframe/jpg/offset/3/w/480/h/281";
      let line = [{"name":this.videoUrl.live_infos[0].name,"url":this.videoUrl.live_infos[0].url},{"name":this.videoUrl.live_infos[2].name,"url":this.videoUrl.live_infos[2].url}]
      console.log(line);
      // var player = new Aliplayer({
      //   "id": "player-con",
      //   "source": url,
      //   "autoplay": true,
      //   "isLive": true,
      //   "rePlay": false,
      //   "playsinline": true,
      //   "preload": true,
      //   "controlBarVisibility": "hover",
      //   "useH5Prism": true,
      //   skinLayout: [
      //   {name: "bigPlayButton", align: "blabs", x: 30, y: 80},
      //   {name: "errorDisplay", align: "tlabs", x: 0, y: 0},
      //   {name: "infoDisplay", align: "cc"},
      //   {
      //     name: "controlBar", align: "blabs", x: 0, y: 0,
      //     children: [
      //         {name:"liveDisplay", align:"tlabs", x: 15, y:6},
      //         {name:"fullScreenButton", align:"tr", x:10, y: 10},
      //         {name:"subtitle", align:"tr",x:15, y:12},
      //         {name:"setting", align:"tr",x:15, y:12},
      //         {name:"volume", align:"tr", x:5, y:10}
      //       ]
      //   }
      // ]
      // }, function (player) {
      //     console.log("The player is created");
      //   }
      // );
      let player = new HlsJsPlayer({
        id: 'mse',
        url: url,
        isLive: true,
        autoplay: true,
        playsinline: true,
        id: "mse",
        url: url,
        playsinline: true,
        whitelist: [""],
        autoplay: true,
        fluid: true,
        resourceReady:"线路一",
        poster: player
      });
      player.emit('resourceReady', line)
    }
  }
//   props: {
//     videoUrl: "",
//     poster: ""
//   },
//   data () {
//     return {
//       playerOptions: {
//         url: 'http://pili-live-hls.course.ruyiku.cn/ruyiku/ruyiku.m3u8',
//         poster: '',
//         width: '100%' // Depends on its wrapper element.
//       }
//     }
//   },
//   mounted() {
//     // console.log(this.videoUrl)

//     // this.playerOptions.height = this.$refs.videoBox1.clientHeight;
//     // // this.videoUrl = this.$route.query.url;
//     // console.log(this.videoUrl)
//     // this.playerOptions.url = this.videoUrl;
//     // this.playerOptions.poster =
//     //   this.videoUrl + "?vframe/jpg/offset/3/w/480/h/281";
//     // if (this.poster) {
//     //   this.playerOptions.poster = this.poster;
//     // }
//     // this.$refs.videoPlayer.player.reset();
//   }
// }
</script>

<style lang="less" scoped>
#player-con{
  width: 100%;
  // height: auto;
}
.videoBox1 {
  width: 100%;
  height: 4.22rem;
  position: relative;
  // border: 1px solid red;
  div {
    height: 100%;
  }
  /deep/.vjs-big-play-button {
    border-radius: 50% !important;
    width: 2em !important;
    margin-left: -1em !important;
    z-index: 99;
    .vjs-icon-play {
      &:before {
        padding: 0.05rem !important;
      }
    }
  }
  /deep/.vjs-progress-control {
    .vjs-load-progress {
      background: #ffffff !important;
    }
    .vjs-play-progress {
      background: #e8380d;
      &::before {
        color: #e8380d;
      }
    }
    div {
      background: #ffffff;
    }
  }
  /deep/.vjs-loading-spinner {
    height: 50px !important;
  }
  /deep/.vjs-custom-skin {
    z-index: 3;
  }
  /deep/.video-js {
    width: 100% !important;
  }
  /deep/.vjs-control-bar {
    background-color: rgba(43, 51, 63, 0.3);
  }
}
.aniiu{
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99999;
  width: auto;
  height: 1rem;
  color: #fff;
}
.xgplayer {
    background:url(/imgs/aaa.jpg) top!important;
    background-size: 100% auto;
}
</style>