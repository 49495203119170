<template>
  <div class="ryk-video">
    <div
      class="head-video"
      style="position: relative;"
    >
      <VideoSplayer
        v-if="videoDetail.video"
        @hidePlayBtn="hidePlayBtn"
        :videoUrl="videoDetail.video"
      ></VideoSplayer>
      <div
        class="no-video"
        v-if="!videoDetail.video && !isError"
      >
        <img
          src="imgs/loading.svg"
          alt=""
        >
      </div>
      <div
        class="is-error"
        v-if="!videoDetail.video && isError"
      >
        <img
          @click="errorPlay"
          src="imgs/play.png"
          alt=""
        >
      </div>
    </div>
    <div class="head-title">
      <ShareBtn :type="'gray'"></ShareBtn>
      <p class="name">{{videoDetail.title}}</p>
      <p class="lecturer">{{videoDetail.lecturer}}</p>
      <p class="times">播放：{{videoDetail.play_count}} 次. {{videoDetail.create_time}}</p>
    </div>
    <div
      class="video-content"
      style="overflow-y: auto;"
      v-html="videoDetail.desc"
    >
    </div>
    <ToApp :appLink="{
      type: 'video',
      id: $route.query.id
    }"></ToApp>
  </div>
</template>

<script>
import VideoSplayer from '@/components/VideoSplayer.vue';
import ToApp from '@/components/ToApp.vue';
import ShareBtn from '@/components/ShareBtn.vue';
import { get } from '../http.js'

export default {
  components: {
    VideoSplayer,
    ToApp,
    ShareBtn
  },
  data() {
    return {
      videoDetail: {},
      showPlayBtn: true,
      isError: false
    }
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      // 44
      let { id } = this.$route.query;
      get({ url: `video/${id}/` }).then((response) => {
        let res = response;
        let quality = []
        if (res.urls.length > 0) {
          quality = res.urls.map(val => {
            let index = val.lastIndexOf("-");
            let name = val.substring(index + 1, val.length);
            name = name.split('.')[0];
            if (name.indexOf('p') === -1) {
              name = '原画';
            }
            return {
              name: name,
              url: val,
              type: 'auto'
            }
          })
        } else {
          quality = [{
            name: '高清',
            url: res.url,
            type: 'auto'
          }]
        }
        this.videoDetail = {
          create_time: res.create_time,
          desc: res.desc,
          is_collection: res.is_collection,
          pic: res.pic,
          play_count: res.play_count,
          time: res.time,
          title: res.title,
          url: res.url,
          video: quality[0].url,
          id: res.id,
          // lecturer: res.album.lecturer_display,
		  lecturer_name: res.lecturer_name
        }
      }).catch(error => {
        this.isError = true;
        let res = error;
        this.videoDetail = {
          create_time: res.create_time,
          desc: res.desc,
          is_collection: res.is_collection,
          pic: res.pic,
          play_count: res.play_count,
          time: res.time,
          title: res.title,
          url: res.url,
          video: null,
          id: res.id,
          lecturer: res.album.lecturer_display
        }
      })
    },
    hidePlayBtn() {
      this.showPlayBtn = false;
    },
    errorPlay() {
      this.$store.commit('changePermission')
    }
  },
}
</script>
<style lang="less" scoped>
.ryk-video {
  width: 100%;
  min-height: 100vh;
  background: #f0f0f0;
}
.head-title {
  padding: 0.3rem 0.24rem;
  background: #ffffff;
  position: relative;
  .name {
    font-size: 0.48rem;
    font-weight: normal;
    color: rgb(51, 51, 51);
    line-height: 0.7rem;
  }
  .lecturer {
    font-size: 0.36rem;
    font-weight: normal;
    color: rgb(102, 102, 102);
    line-height: 0.59rem;
  }
  .times {
    margin-top: 0.1rem;
    font-size: 0.24rem;
    font-weight: 300;
    color: rgb(102, 102, 102);
    line-height: 0.33rem;
  }
}
.no-video {
  width: 100%;
  height: 4.22rem;
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 1.5rem;
    animation: myRotate 2s linear infinite;
  }
  @keyframes myRotate {
    0% {
      -webkit-transform: rotate(0deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
}
.is-error {
  width: 100%;
  height: 4.22rem;
  background-image: url(http://audio-cdn.ruyiku.cn/static/files/佛法banner图片.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  opacity: 0.8;
  img {
    width: 1rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}
.video-content {
  padding: 0.3rem;
  padding-bottom: 1.8rem;
}
.share-icon {
  position: absolute;
  right: 0.5rem;
  color: #919191;
}
</style>
