<template>
  <div class="audio-album">
    <div
      class="audio-album-head"
      :style="{ background: backgroundImg}"
    >
    </div>
    <div class="audio-header-content">
      <div class="left-img">
        <img
          :src="albumDetail.pic"
          onerror="this.src='imgs/位图.png'"
          alt=""
        >
      </div>
      <div class="right-content">
        <p class="title ellipsis-2">{{albumDetail.name}}</p>
        <p class="content ellipsis-1">{{albumDetail.lecturer}}</p>
      </div>
    </div>
    <div class="audio-album-content">
      <p class="list-num">共{{albumList.length}}个音频</p>
      <div
        class="audio-album-item"
        v-for="(item, index) in albumList"
        :key="item.id"
      >
        <div class="left">
          {{index+1}}
        </div>
        <div
          class="right"
          @click="toAudio(item)"
        >
          <p class="title ellipsis-1">
            {{item.title}}
          </p>
          <div class="time-times">
            <i class="el-icon-time"></i>
            <span>{{item.time}}</span>
            <i class="el-icon-headset"></i>
            <span>{{item.play_count}} · {{item.create_time.substring(0, 10)}}</span>
          </div>
        </div>
      </div>
    </div>
    <ToApp :appLink="{
      type: 'audioAlbum',
      id: $route.query.id
    }"></ToApp>
    <ShareBtn></ShareBtn>
  </div>
</template>

<script>
import ToApp from '@/components/ToApp.vue';
import ShareBtn from '@/components/ShareBtn.vue';
import { get } from '../http.js'
export default {
  components: {
    ToApp,
    ShareBtn
  },
  data() {
    return {
      backgroundImg: '',
      albumDetail: {
        pic: 'imgs/位图.png'
      },
      albumList: [],
      isError: false
    }
  },
  mounted() {
    //68
    let { id } = this.$route.query;
    get({ url: `album/${id}/` }).then((response) => {
      let data = response;
      this.backgroundImg = `url(${data.cover + '?imageView2/1/w/200/h/200/q/50'})`
      this.albumDetail = {
        pic: data.cover,
        name: data.name,
        lecturer: data.lecturer_display,
      }
      this.albumList = data.items;
    }).catch(error => {
      this.isError = true;
      let data = error;
      this.backgroundImg = `url(${data.cover + '?imageView2/1/w/200/h/200/q/50'})`
      this.albumDetail = {
        pic: data.cover,
        name: data.name,
        lecturer: data.lecturer_display,
      }
      this.albumList = data.items;
    })
  },
  methods: {
    toAudio(item) {
      if (this.isError) {
        this.$store.commit('changePermission')
      } else {
        this.$router.push('audio?id=' + item.id)
      }
    }
  }
}
</script>

<style lang='less' scoped>
.audio-album {
  width: 100%;
  min-height: 100vh;
  background: #f0f0f0;
}
.audio-album-head {
  width: 100%;
  height: 4rem;
  background: url(/imgs/位图.png);
  background-repeat: no-repeat !important;
  background-size: cover !important;
  position: relative;
  filter: blur(2px) opacity(0.9);
  &:before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 4rem;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
.audio-header-content {
  position: absolute;
  width: 100%;
  top: 0.8rem;
  .left-img {
    position: absolute;
    left: 0.3rem;
    img {
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 4px;
      object-fit: cover;
      background: #ffffff;
    }
  }
  .right-content {
    position: absolute;
    left: 2.7rem;
    width: 3.88rem;
    height: 1rem;
    .title {
      font-size: 0.48rem;
      font-weight: normal;
      color: rgba(255, 255, 255, 1);
      line-height: 0.7rem;
    }
    .content {
      font-size: 0.36rem;
      font-weight: normal;
      color: rgb(255, 255, 255);
      line-height: 0.59rem;
    }
  }
}
.audio-album-content {
  padding: 0.3rem;
  padding-bottom: 1.5rem;
  .list-num {
    font-size: 0.26rem;
    font-weight: 300;
    color: rgb(153, 153, 153);
    line-height: 0.37rem;
  }
  .audio-album-item {
    display: flex;
    padding: 0.2rem 0;
    border-bottom: 1px solid #cccccc;
    .left {
      width: 0.4rem;
      font-size: 0.28rem;
      color: #606060;
      padding: 0.1rem;
    }
    .right {
      width: 5.5rem;
      .title {
        font-size: 0.48rem;
        font-weight: normal;
        color: rgb(51, 51, 51);
        line-height: 0.7rem;
      }
      .time-times {
        margin-top: 0.2rem;
        font-size: 0.24rem;
        font-weight: 300;
        color: rgb(102, 102, 102);
        line-height: 0.33rem;
        font-family: PingFangSC-Light, PingFang SC;
        span {
          padding: 0 0.1rem;
        }
      }
    }
  }
}
.share-icon {
  position: absolute;
  top: 0.3rem;
  right: 0.5rem;
  color: #ffffff;
  z-index: 1;
}
</style>