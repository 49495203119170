import axios from 'axios';
axios.defaults.baseURL = 'https://api.ruyiku.cn/'
axios.defaults.timeout = 5000;
// axios.defaults.withCredentials = true;
// axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // if (error.response) {
    //   switch (error.response.status) {
    //     case 403:
    //       axios.get('user/authorize/').then(res => {
    //         location.href = res.data.url;
    //       })
    //   }
    // }
    console.log(error.response)
    return Promise.reject(error.response.data)
  });
export function get(options) {
  const {
    url,
    data
  } = options;
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: data
    }).then(res => {
      resolve(res.data)
    }).catch(
      err => {
        reject(err)
      }
    )
  })
}
export function post(options) {
  const {
    url,
    data
  } = options;
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(res => {
      resolve(res.data)
    }).catch(
      err => {
        reject(err)
      }
    )
  })
}