<template>
  <div class="share-icon">
    <img
      :src="img"
      alt=""
      @click="showShare"
    />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String
    }
  },
  data() {
    return {
      isWx: false,
      img: 'imgs/share-white.png'
    }
  },
  mounted() {
    if (this.type) {
      this.img = 'imgs/share-gray.png'
    }
    var ua = navigator.userAgent.toLowerCase();
    this.isWx = ua.match(/MicroMessenger/i) == "micromessenger"
  },
  methods: {
    copyToClipboard() {
      let txt = window.location.href;
      const input = document.createElement('input');
      document.body.appendChild(input);
      input.setAttribute('value', txt);
      input.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        this.$toast('分享链接已复制到粘贴板');
      }
      document.body.removeChild(input);
    },
    showShare() {
      if (this.isWx) {
        this.$store.commit('changeMask')
      } else {
        this.copyToClipboard()
        // this.$store.commit('changeMask')
      }
    }
  },
}
</script>

<style lang='less' scoped>
.share-icon {
  img {
    width: 0.5rem;
  }
}
</style>