<template>
  <div class="footer-btn">
    <div class="open-app">
      <div
        class="title"
        @click="toApp()"
      >
        在APP中打开
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    appLink: {
      type: Object
    }
  },
  methods: {
    toApp() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (isIOS) {
        console.log('ios')
        if (this.appLink.type == 'video') {
          location.href = 'https://ulink.ruyiku.cn/video/id=' + this.appLink.id
        }
        if (this.appLink.type == 'audioAlbum') {
          location.href = 'https://ulink.ruyiku.cn/voiceAlbum/id=' + this.appLink.id
        }
        if (this.appLink.type == 'videoAlbum') {
          location.href = 'https://ulink.ruyiku.cn/videoAlbum/id=' + this.appLink.id
        }
        if (this.appLink.type == 'voice') {
          location.href = 'https://ulink.ruyiku.cn/voice/id=' + this.appLink.id
        }
        if (this.appLink.type == 'live') {
          location.href = 'https://ulink.ruyiku.cn/course/111'
        }
      }
      if (isAndroid) {
        console.log('安卓')
        if (this.appLink.type == 'video') {
          location.href = 'meethappy://wishes/app?id=' + this.appLink.id + '&type=video'
        }
        if (this.appLink.type == 'audioAlbum') {
          location.href = 'meethappy://wishes/app?id=' + this.appLink.id + '&type=audioAlbum'
        }
        if (this.appLink.type == 'videoAlbum') {
          location.href = 'meethappy://wishes/app?id=' + this.appLink.id + '&type=videoAlbum'
        }
        if (this.appLink.type == 'voice') {
          location.href = 'meethappy://wishes/app?id=' + this.appLink.id + '&type=voice'
        }
        if (this.appLink.type == 'live') {
          location.href = 'meethappy://wishes/app?type=live'
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.footer-btn {
  width: 6.9rem;
  height: 1rem;
  position: fixed;
  margin: 0.3rem;
  bottom: 0;
  background-image: url(/imgs/share.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.title {
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgb(24, 45, 127);
  line-height: 0.62rem;
  width: 2.2rem;
  height: 0.62rem;
  background: #ffffff;
  text-align: center;
  position: absolute;
  right: 0.18rem;
  top: 0.18rem;
}
</style>